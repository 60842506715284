import React from "react";


export default function FormButton({onclick}) {
  return (
    <div>
      <br />
      <div align="center">
        <div className="row mb-5 column-gap-6">
          <div className="col d-flex justify-content-center gap-5">
            {/* <Button
              type="submit"
              className="btn btn-success text-uppercase"
              buttonText="Add Barcode"
              
            /> */}
            
          </div>
        </div>
      </div>
    </div>
  );
}
