import * as XLSX from 'xlsx';
import React, { useState, useEffect } from 'react';
import FormButton from './UI/FormButton';
import { Label, TextField } from './UI/FormComponent';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router';
import "./barcode.css";



function Barcode() {
    const [barcode, setBarcode] = useState({ EnvNo: "", serialNo: "" });
    const [db, setDb] = useState(null);
    const [error, setError] = useState('');
    const [enumError, setEnumError] = useState('');
    const [count, setCount] = useState(0);
    const navigate = useNavigate();



    const queryParams = new URLSearchParams(window.location.search);
    const vehicleNo = queryParams.get('vehicleNo') || 0;





    useEffect(() => {
        const request = indexedDB.open('BarcodeDatabase', 2);

        request.onupgradeneeded = (event) => {
            const db = event.target.result;
            if (!db.objectStoreNames.contains('barcode_entry')) {
                const objectStore = db.createObjectStore('barcode_entry', {
                    keyPath: 'Barcode_id',
                    autoIncrement: true,
                });
                objectStore.createIndex('vehicleNo', 'vehicleNo', { unique: false });
                objectStore.createIndex('enNum', 'enNum', { unique: false });
                objectStore.createIndex('serialNo', 'serialNo', { unique: false });
            }
        };

        request.onsuccess = (event) => {
            const db = event.target.result;
            setDb(db);
            getCountFromIndexedDB(db); // Initialize count
        };

        request.onerror = (event) => {
            console.error('Database error:', event.target.errorCode);
        };
    }, []);

    const getCountFromIndexedDB = (db) => {
        const transaction = db.transaction(['barcode_entry'], 'readonly');
        const objectStore = transaction.objectStore('barcode_entry');
        const countRequest = objectStore.count();

        countRequest.onsuccess = (event) => {
            setCount(event.target.result);
        };

        countRequest.onerror = (event) => {
            console.error('Error getting count:', event.target.errorCode);
        };
    };

    // const addUser = () => {
    //     if (!db) {
    //         console.error('Database not initialized.');
    //         return;
    //     }

    //     const transaction = db.transaction(['barcode_entry'], 'readwrite');
    //     const objectStore = transaction.objectStore('barcode_entry');
    //     const newUser = {
    //         vehicleNo,
    //         enNum: barcode.EnvNo,
    //         serialNo: barcode.serialNo
    //     };

    //     if (newUser.enNum.length !== 13) {
    //         setEnumError('Enter a valid length of 13 numbers');
    //         setFormValid(false);
    //         setTimeout(() => setEnumError(''), 3000);
    //         return;
    //     }
    //     if (newUser.enNum.length === 13) {
    //         serialNoRef.current.focus();
    //     }

    //     // Function to check if serialNo is unique
    //     const checkSerialNoUnique = () => {
    //         return new Promise((resolve, reject) => {
    //             const index = objectStore.index('serialNo');
    //             const request = index.openCursor(IDBKeyRange.only(newUser.serialNo));

    //             request.onsuccess = (event) => {
    //                 const cursor = event.target.result;
    //                 if (cursor) {
    //                     setError('Serial number must be unique.');
    //                     setFormValid(false);
    //                     setTimeout(() => setError(''), 3000);
    //                     resolve(false);
    //                 } else {
    //                     resolve(true);
    //                 }
    //             };

    //             request.onerror = (event) => {
    //                 console.error('Error checking serial number:', event.target.errorCode);
    //                 reject(event.target.errorCode);
    //             };
    //         });
    //     };

    //     // Perform the check and add user if serialNo is unique
    //     checkSerialNoUnique().then(isUnique => {
    //         if (isUnique) {
    //             const addRequest = objectStore.add(newUser);

    //             addRequest.onsuccess = () => {
    //                 console.log("Barcode entry added successfully.");
    //                 getCountFromIndexedDB(db); // Refresh count after adding
    //             };

    //             addRequest.onerror = (event) => {
    //                 console.error('Error adding barcode entry:', event.target.errorCode);
    //             };
    //         }
    //     });
    // };
    const addUser = () => {
        if (!db) {
            console.error('Database not initialized.');
            return;
        }

        const transaction = db.transaction(['barcode_entry'], 'readwrite');
        const objectStore = transaction.objectStore('barcode_entry');
        const newUser = {
            vehicleNo,
            enNum: barcode.EnvNo,
            serialNo: barcode.serialNo
        };

        if (newUser.enNum && (newUser.enNum.length !== 13)) {
            setEnumError('Enter a valid length of 13 numbers');
            // setFormValid(false);
            setTimeout(() => setEnumError(''), 3000);
            return;
        }

        const checkSerialNoUnique = () => {
            return new Promise((resolve, reject) => {
                const index = objectStore.index('serialNo');
                const request = index.openCursor(IDBKeyRange.only(newUser.serialNo));

                request.onsuccess = (event) => {
                    const cursor = event.target.result;
                    if (cursor) {
                        setError('Serial number must be unique.');
                        // setFormValid(false);
                        setTimeout(() => setError(''), 3000);
                        resolve(false);
                    } else {
                        resolve(true);
                    }
                };

                request.onerror = (event) => {
                    console.error('Error checking serial number:', event.target.errorCode);
                    reject(event.target.errorCode);
                };
            });
        };

        checkSerialNoUnique().then(isUnique => {
            if (isUnique) {
                const addRequest = objectStore.add(newUser);

                addRequest.onsuccess = () => {
                    console.log("Barcode entry added successfully.");
                    getCountFromIndexedDB(db);
                };

                addRequest.onerror = (event) => {
                    console.error('Error adding barcode entry:', event.target.errorCode);
                };
            }
        });
    };

    const exportToExcel = () => {
        if (!db) {
            console.error('Database not initialized.');
            return;
        }

        const transaction = db.transaction(['barcode_entry'], 'readonly');
        const objectStore = transaction.objectStore('barcode_entry');
        const request = objectStore.getAll();

        request.onsuccess = (event) => {
            const data = event.target.result;

            // Create a map to group serial numbers by enNum and to keep track of vehicle numbers
            const enNumMap = {};
            const vehicleNoSet = new Set();

            // Populate the map with enNums as keys and arrays of serialNos as values
            data.forEach(item => {
                const vehicleNo = item.vehicleNo || '';
                const enNum = item.enNum || '';
                const serialNo = item.serialNo || '';

                vehicleNoSet.add(vehicleNo);

                if (!enNumMap[enNum]) {
                    enNumMap[enNum] = [];
                }

                enNumMap[enNum].push(serialNo);
            });

            // Convert vehicleNoSet to an array and take the first one (assuming all items have the same vehicleNo)
            const vehicleNo = Array.from(vehicleNoSet)[0] || '';

            // Create an array to hold the rows for the Excel sheet
            const formattedData = [];

            // Add header row
            formattedData.push(['Vehicle No', 'EN Number', 'Serial No']);

            // Add a row for the vehicle number
            formattedData.push([vehicleNo, '', '']);

            // Add rows for each enNum
            for (const enNum in enNumMap) {
                // Add a row for the enNum
                formattedData.push(['', enNum, '']);

                // Add associated serialNos
                const serialNos = enNumMap[enNum];
                serialNos.forEach(serialNo => {
                    formattedData.push(['', '', serialNo]);
                });

                // Add a blank row for separation
                formattedData.push(['', '', '']);
            }

            // Convert the formatted data to a worksheet and create a new workbook
            const ws = XLSX.utils.aoa_to_sheet(formattedData);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Barcode Entries');

            // Determine the filename
            const filename = vehicleNo ? `${vehicleNo}.xlsx` : 'barcode_entries.xlsx';

            // Write the workbook to a file
            XLSX.writeFile(wb, filename);
        };


        request.onerror = (event) => {
            console.error('Error retrieving data for Excel export:', event.target.errorCode);
        };
    };

    const submitHandler = async () => {



        addUser();
        setBarcode({ serialNo: "" });
        // toast.success("Successful saved.", { position: "top-right" });
        if (!error) {
            toast.success("Successful saved.", { position: "top-right" });
        } else {
            toast.error("Serial no is not valid", { position: "top-right" });
        }
        // try {
        //     addUser();
        //     setBarcode({ serialNo: "" });
        //     // toast.success("Successfully saved.", { position: "top-right" });
        // } catch (error) {
        //     toast.error("Serial no is not valid", { position: "top-right" });
        // }
    };

    const cancelHandler = (event) => {
        event.preventDefault();
        setBarcode({ EnvNo: "", serialNo: "" });
        toast.error("Cancellation successful.", { position: "top-right" });
    };

    const changeHandlerEno = (event) => {
        const { name, value } = event.target;
        // ({ ...prev, [name]: value })
        setBarcode(prev => {
            const updatedBarcode = { ...prev, [name]: value };

            console.log(event.keyCode, "+event.key");
            // console.log((value.length), "value");

            if (name === 'EnvNo' && value.length === 13) {
                // console.log("Hello");

                document.getElementById('serialNo').focus();
            }



            return updatedBarcode;
        });

    };
    const changeHandlerSer = (event) => {
        const { name, value } = event.target;
        // ({ ...prev, [name]: value })
        setBarcode(prev => {
            const updatedBarcode = { ...prev, [name]: value };

            console.log(event.keyCode, "+event.key");
            // console.log((value.length), "value");



            if (name === 'serialNo' && value) {
                submitHandler();
                document.getElementById('serialNo').value = "";
            }


            return updatedBarcode;
        });

    };
    const clearIndexedDB = () => {
        if (!db) {
            console.error('Database not initialized.');
            return;
        }

        const transaction = db.transaction(['barcode_entry'], 'readwrite');
        const objectStore = transaction.objectStore('barcode_entry');
        const clearRequest = objectStore.clear();

        clearRequest.onsuccess = () => {
            console.log('All records cleared from IndexedDB.');
            setCount(0);
            toast.success('All records cleared successfully.', { position: 'top-right' });
        };

        clearRequest.onerror = (event) => {
            console.error('Error clearing IndexedDB:', event.target.errorCode);
        };

        navigate('/VehicalNo');
    };


    const handleKeyDown = (event) => {
        const key = event.key;
        if (key === "Backspace" || key === "Delete") {

            // Focus on the EnvNo field
            document.getElementById('EnvNo').focus();
            document.getElementById('EnvNo').value = "";
            document.getElementById('serialNo').value = "";
        }
    };

    return (
        <div className='wrapper m-0 pt-0 d-flex flex-column bg-gray-300 flex-row-fluid' id='kt_wrapper' style={{ width: '100%' }}>
            <div id="kt_content_container" className="container-xxl mb-4 mt-5">
                <div className='card p-0 px-4'>
                    <h3 className='ms-1 my-0 d-flex fw-normal align-items-center  d-flex justify-content-between'>
                        <div>

                            <span className='fs-6' >&nbsp; Vehicle No</span>
                            <h3 className='ms-2 fw-bold align-items-center mb-0 me-5 vehicle-no'>{vehicleNo}</h3>
                        </div>
                        <div>
                            <br />
                            <div align="center">
                                <div className="row mb-5 column-gap-6">
                                    <div className="col d-flex justify-content-center gap-5">
                                        <button type="submit" className="btn btn-danger text-uppercase" onClick={clearIndexedDB} >
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="download-i me-2"><path fill="#ffffff" d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" /></svg>
                                            Clear
                                        </button>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </h3>
                </div>
                <form onSubmit={submitHandler} className='needs-validation' noValidate encType="multipart/form-data">
                    <div className="card card-flush shadow-sm pt-0">
                        <div className="card-header py-0 mt-0 px-1">
                            <h3 className="card-title fw-bolder">Barcode</h3>
                        </div>
                        <div className="card-body px-1 py-1 font-weight-bolder">
                            <div className="">
                                <div className="mb-5 fv-row fv-plugins-icon-container">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <Label labelText="EAN-13:" />
                                            <TextField
                                                type="number"
                                                id="EnvNo"
                                                name="EnvNo"
                                                value={barcode.EnvNo}
                                                onChange={changeHandlerEno}
                                                className="form-control mb-1"
                                                // ref={serialNoRef}
                                                placeholder="EAN-13"
                                            />
                                            {enumError && <p style={{ color: 'red' }}>{enumError}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div>
                                        <Label labelText="Serial No:" />
                                        <input
                                            type="text"
                                            id='serialNo'
                                            placeholder="Serial No"
                                            name='serialNo'
                                            value={barcode.serialNo}
                                            onChange={changeHandlerSer}
                                            onKeyDown={handleKeyDown}
                                            className="form-control mb-2 cursor-pointer"
                                        />

                                        {error && <p style={{ color: 'red' }}>{error}</p>}
                                    </div>
                                </div>
                            </div>
                            <FormButton onClick={cancelHandler} />
                        </div>
                    </div>
                </form>
                <div className='card p-0 px-4 d-flex justify-content-between'>
                    <h3 className='ms-1 my-0 d-flex fw-normal align-items-center  d-flex justify-content-between'>
                        <span>
                            Quantity: <span className='ms-2 fw-bold'>{count}</span>
                        </span>

                        <div>
                            <br />
                            <div align="center">
                                <div className="row mb-5 column-gap-6">
                                    <div className="col d-flex justify-content-center gap-5">

                                        <button type="submit" className="btn btn-success text-uppercase ms-3" onClick={exportToExcel} >
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="download-i me-2"><path fill="#ffffff" d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 242.7-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7 288 32zM64 352c-35.3 0-64 28.7-64 64l0 32c0 35.3 28.7 64 64 64l384 0c35.3 0 64-28.7 64-64l0-32c0-35.3-28.7-64-64-64l-101.5 0-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352 64 352zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z" /></svg>
                                            Export
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </h3>
                </div>
            </div>
        </div>
    );
}

export default Barcode;
