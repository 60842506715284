import React, { useState } from 'react'
import { Button, Label, TextField } from './UI/FormComponent'
import { useNavigate } from 'react-router-dom'
import toast from 'react-hot-toast';

function VehicalNo() {
    const [Vehical, setVehical] = useState({
        vehicalNo: "",
    });

    const navigate = useNavigate();
    const ChangeHandler = (event) => {
        const { name, value } = event.target;
        setVehical((prev) => ({
            ...prev,
            [name]: value,
        }));
        console.log(Vehical);
    };
    const submitHandler = async (event) => {
        event.preventDefault();
        setVehical({
            vehicalNo: "",
        });
        if (Vehical.vehicalNo === "") {
            toast.error("Invalid Credential !!", { position: "top-right" });
        } else {
            toast.success("successful saved.", { position: "top-right" });
            navigate(`/barcode?vehicleNo=${Vehical.vehicalNo}`);
        }

        console.log(Vehical.vehicalNo);

    };


    return (
        <div className='wrapper d-flex flex-column bg-gray-300 flex-row-fluid' id='kt_wrapper' style={{ width: '100%' }}>
            <div id="kt_content_container" className="container-xxl mb-4 mt-5">
                <form onSubmit={submitHandler} className='needs-validation' novalidate encType="multipart/form-data">
                    <div className="card card-flush shadow-sm ">
                        <div className="card-header py-0">
                            <h3 className="card-title fw-bolder">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" style={{ width: '5%', marginRight: "10px" }}><path d="M48 0C21.5 0 0 21.5 0 48L0 368c0 26.5 21.5 48 48 48l16 0c0 53 43 96 96 96s96-43 96-96l128 0c0 53 43 96 96 96s96-43 96-96l32 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l0-64 0-32 0-18.7c0-17-6.7-33.3-18.7-45.3L512 114.7c-12-12-28.3-18.7-45.3-18.7L416 96l0-48c0-26.5-21.5-48-48-48L48 0zM416 160l50.7 0L544 237.3l0 18.7-128 0 0-96zM112 416a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm368-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z" /></svg>
                                Vehical
                            </h3>
                        </div>
                        <div className="card-body py-1 font-weight-bolder">
                            <div className="container">
                                <div className="mb-5 fv-row fv-plugins-icon-container">
                                    <div className="d-flex column-gap-5 ">
                                        <div className="row">

                                            <Label className="col-12" labelText="Vehical No :" />
                                            <TextField
                                                type="string"
                                                id="vehicalNo"
                                                name="vehicalNo"
                                                value={Vehical.vehicalNo}
                                                onChange={ChangeHandler}
                                                className="form-control mb-2"
                                                placeholder="Vehical No"
                                                requiredd={false}
                                            />

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div align="center">
                                <div className="row mb-5 column-gap-6">
                                    <div className="col d-flex justify-content-center gap-5">
                                        <Button
                                            type="submit"
                                            className="btn btn-success text-uppercase"
                                            buttonText="Submit"
                                            onclick={submitHandler} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default VehicalNo