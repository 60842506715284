import { Route, Routes } from "react-router";
import "./App.css";
import { Login } from "./Components/Login";
import Barcode from "./Components/Barcode";
import VehicalNo from "./Components/VehicalNo";

function App() {
  return (
    <div className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed bg-gray-00">
      <div className="d-flex flex-column flex-root min-vh-100">
        <div className="page d-flex flex-row flex-column-fluid">
          {/* <Sidebar /> */}
          <div className="d-flex flex-column flex-row-fluid">
            {/* <TopBar /> */}
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/barcode" element={<Barcode />} />
              <Route path="/VehicalNo" element={<VehicalNo />} />
              {/* Other routes */}
            </Routes>
          </div>
        </div>
        <div  className='footer '>
                <div  className='container-fluid'>
                    <div  className='row'>
                        <div  className='col-md-12 d-flex justify-content-center' style={{padding: '20px 0', fontSize:'15px'}}>
                            Powered By Unique IT Solution
                        </div>
                    </div>
                </div>
            </div>
        {/* <Footer /> */}
      </div>
    </div>
  );
}

export default App;


//    const [db, setDb] = useState(null);

// useEffect(() => {
//   // Open (or create) the database
//   const request = indexedDB.open('BarcodeDatabase', 2);  // Incremented version to 2

//   request.onupgradeneeded = (event) => {
//       console.log("onupgradeneeded: Setting up the database.");
//       const db = event.target.result;
//       if (!db.objectStoreNames.contains('barcode_entry')) {
//           const objectStore = db.createObjectStore('barcode_entry', {
//               keyPath: 'Barcode_id',
//               autoIncrement: true,
//           });
//           // objectStore.createIndex('vehicleNo', 'vehicleNo', { unique: false });
//           objectStore.createIndex('barcode.EnvNo', 'barcode.EnvNo', { unique: false });
//           objectStore.createIndex('barcode.serialNo', 'barcode.serialNo', { unique: false });

//           // console.log("Object store 'users' created.");
//       } else {
//           console.log("Object store 'users' already exists.");
//       }
//   };

//   request.onsuccess = (event) => {
//       console.log("Database opened successfully.");
//       const db = event.target.result;
//       setDb(db);
//       displayData(db);
//   };

//   request.onerror = (event) => {
//       console.error('Database error:', event.target.errorCode);
//   };
// }, []);

// const addUser = (serialNum , enNum) => {
//   if (!db) {
//       console.error('Database not initialized.');
//       return;
//   }
//   if (!db.objectStoreNames.contains('barcode_entry')) {
//       console.error("Object store 'users' not found.");
//       return;
//   }
//   const transaction = db.transaction(['barcode_entry'], 'readwrite');
//   const objectStore = transaction.objectStore('barcode_entry');

//   const newUser = { enNum, serialNum };
//   const request = objectStore.add(newUser);

//   request.onsuccess = () => {
//       console.log("User added successfully.");
//       setVehicleNo('');
//       setenNum('');
//       serialNum('');
//       displayData(db);
//   };

//   request.onerror = (event) => {
//       console.error('Error adding user:', event.target.errorCode);
//   };
// };