import toast from 'react-hot-toast';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import '../assets/css/style.bundle.css'
// import getmac from 'getmac';
import { TextField, Button, Label } from '../Components/UI/FormComponent';





export function Login() {

    // getmac.getMac((err, macAddress) => {
    //   if (err) throw err;
    //   console.log('MAC Address:', macAddress);
    // });

    const navigate = useNavigate();
    const [ShowPassword, setShowPassword] = useState(false);
    const [user, setUser] = useState({
        email: "",
        password: "",
        apikey: "Unique@1234567890@"
    });

    function ChangeHandler(event) {
        setUser((prev) => (
            {
                ...prev, [event.target.name]: event.target.value
            }
        ))
    }

    const submitHandler = async (event, res, req) => {
        event.preventDefault();

        if (user.email === "abc@gmail.com" && user.password === "123456") {

            const dupToken = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJVc2VySUQiOjEsImV4cCI6MTcyNzk2MjE2MH0.1ayZ1Ykr3rvPl0zNfsaMQA-ASLhlPKZTWszT854fp48";
            // const secKey = "Jay@12345@";
            const expireTime = new Date();
            expireTime.setDate(expireTime.getDate() + 30);


            // const decodToken = jwt 
            if (!dupToken) {
                console.log("Token is not valid or Token is not given");
            }

            localStorage.setItem('token', dupToken);
            localStorage.setItem('exptime', expireTime);

            toast.success("Login Successfully", { position: "top-right" });
            navigate('/VehicalNo');
        }
        else {
            toast.error("Invalid Credential !!", { position: "top-right" });
        }

    }


    const isTokenExpire = () => {
        const expireTime = localStorage.getItem('exptime');
        if (expireTime && new Date() > new Date(expireTime)) {
            localStorage.removeItem('token');
            localStorage.removeItem('exptime');
            return true;
        }
        return false;
    }

    useEffect(() => {
        if (isTokenExpire()) {
            navigate('/')
        }
    }, [navigate]);
    // style={{ backgroundImage: `url(${backgroundImage})` }}
    return (
        <div>
            <div className="d-flex flex-column flex-root min-vh-100">
                <div className="d-flex flex-column flex-column-fluid  position-x-center bgi-no-repeat  bgi-attachment-fixed"
                    style={{ backgroundColor: 'rgb(0, 50, 115)' }}

                >
                    <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
                        <p className="mb-12">
                            <img alt="Logo" src="https://cqpplefitting.com/ad_cqpple/Admin/assets/LOGO/Png_UniqueIT-min.png" style={{ height: "7s0px", width: "250px " }} />
                        </p>
                        <div className="w-lg-500px  rounded shadow-sm p-10 p-lg-15 mx-auto">
                            <form onSubmit={submitHandler} className="form w-100" id="kt_sign_in_form">
                                <div className="fv-row mb-8">
                                    <Label className="text-light" labelText="Email" />
                                    <TextField
                                        type="email"
                                        id='email'
                                        name='email'
                                        value={user.email}
                                        onChange={ChangeHandler}
                                        className="form-control form-control-lg form-control-solid"
                                        placeholder="email@gmail.com"
                                    />
                                </div>
                                <div className="fv-row mb-10 position-relative">
                                    <Label className="text-light" labelText="Password" />
                                    <TextField
                                        type={ShowPassword ? ("text") : ("password")}
                                        id='password'
                                        name='password'
                                        value={user.password}
                                        onChange={ChangeHandler}
                                        className="form-control form-control-lg form-control-solid"
                                        placeholder="*****"
                                    />
                                    <span className='position-absolute right-3 top-[38px] cursor-pointer' style={{ right: "3%", color: "black", top: "48%" }} onClick={() => setShowPassword((prev) => !prev)}>
                                        {
                                            ShowPassword ? (<AiOutlineEyeInvisible fontSize={24} fill='#afb2bf' />) : (<AiOutlineEye fontSize={24} fill='#afb2bf' />)
                                        }
                                    </span>
                                </div>
                                <div className="text-center">
                                    <Button
                                        type="submit"
                                        id="kt_sign_in_submit"
                                        className="btn btn-lg btn-primary w-100 mb-5 indicator-label"
                                        buttonText="Continue"
                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
