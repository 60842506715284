export function TextField({ type, id, name, className, value, placeholder, onChange, requiredd }) {
  return (
    <input
      type={type}
      id={id}
      name={name}
      value={value}
      onChange={onChange}
      className={className}
      placeholder={`${placeholder == null ? `Enter ${name}` : placeholder}  `}
      required={requiredd}
    />
  );
}

export function TextArea({ type, id, name, className, value, placeholder, onChange, requiredd }) {
  return (
    <textarea
      type={type}
      id={id}
      name={name}
      value={value}
      onChange={onChange}
      className={className}
      placeholder={`${placeholder == null ? `Enter ${name}` : placeholder}  `}
      required={requiredd}

    />
  );
}

export function Button({ type, className, buttonText, onclick, icon }) {
  return (
    <button type={type} className={className} onClick={onclick} icon={icon}>
      {buttonText}
    </button>
  );
}

export function Select({ id, className, name, dataControl, dataPaceholder, options, requiredd, onChange, value }) {
  // const [option , setOption]=useState(options)
  return (
    <select
      id={id}
      name={name}
      className={className}
      data-control={dataControl}
      data-placeholder={dataPaceholder}
      required={requiredd}
      onChange={onChange}
      value={value}
    >
      <option value="" disabled>Select </option>
      {options.map((data, index) => (
        <option key={index} value={data.id}>{data.Name}</option>
      ))}
    </select>
  );
}

export function Label({ className, labelText }) {
  return (
    <label htmlFor="validationCustom01" className={`${className}  form-label font-weight-bolder `} >
      {labelText}
    </label>
  );
}